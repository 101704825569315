import React, { Component } from 'react';
import Imgix from "react-imgix"
import { Row, Col } from 'react-grid-system';
import styles from '../styles/scss/components/cardBlogNew.module.scss';
import moment from 'moment';
import Button from './button';

export default class Index extends Component {

    render() {
        const { title, description, date, image, link } = this.props;
        return (
            <Row className={styles.boxWrap}>
                <Col xs={12} md={12} className={styles.boxWrapImage}>
                    <img
                        src={image}
                        height={250}
                        width={672}
                        className={styles.image}                     
                    />
                    <div className={styles.boxImage} >                        
                        <h3 className={styles.cardTitle}>{title}</h3>
                        <p className={styles.time}>
                            <i className='fa fa-clock-o' /> {moment(date).format('MMMM D, YYYY')}
                        </p>
                        {description && <p className={styles.text}>{description}</p>}
                        <Button
                            text="READ MORE"
                            url={`/article/${link}`}
                            className={styles.button}
                            target="_self"
                            newBlue
                            pdf
                            rel="noopener noreferrer"
                        />
                    </div>
                </Col>
            </Row>
        );
    }
}
